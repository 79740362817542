import s from "./calculatorBlock.module.scss"
import GoogleImg from "../../../static/images/companies/refillCompains/GoogleColumn"
import YandexImg from "../../../static/images/companies/refillCompains/YandexColumn"
import TikTokImg from "../../../static/images/companies/refillCompains/TikTokColumn"
import MetaImg from "../../../static/images/companies/refillCompains/MetaColumn"
import React, {useState} from "react";
import Button from "@mui/material/Button";
import CustomInput from "../CustomInput/CustomInput";
import Box from "@mui/material/Box";
const CalculatorBlock = () => {

    const initialCurrentSumData = {
        sumPay: "",
        sum_with_NDS: "",
        sum_cashback_commission: "",
        sumRefill: "",
        helperText: "",
        cashback_commission: "Комиcсия 5%",
        nds: "12%"
    }
    const [currentCompany, setCurrentCompany] = useState("Google")
    const [currentSumData, setCurrentSumData] =useState(initialCurrentSumData)

    const handleChangeCompany = (companyTitle) => {
        switch (companyTitle) {
            // case "Google" :{
            //     setCurrentSumData({...initialCurrentSumData, cashback_commission: "Комиcсия 5% "})
            //     break
            // }
            case "Yandex" :{
                setCurrentSumData({...initialCurrentSumData, cashback_commission: "Кэшбек 10% "})
                break
            }
            // case "TikTok" :{
            //     setCurrentSumData({...initialCurrentSumData, cashback_commission: "Кэшбек 5% "})
            //     break
            // }
            // case "Meta" :{
            //     setCurrentSumData({...initialCurrentSumData, cashback_commission: "Комиcсия 5% "})
            //     break
            // }
            default: setCurrentSumData({...initialCurrentSumData, cashback_commission: "Комиcсия 5% "})
        }
        setCurrentCompany(companyTitle)
    }

    const companiesImg = [
        {title: "Google", img: GoogleImg},
        {title: "Yandex", img: YandexImg},
        {title: "TikTok", img: TikTokImg},
        {title: "Meta", img: MetaImg},
    ]

   const sumRound = (sum) => {
     return   Math.round(sum * 100) / 100
   }

    const calculateClickHandle = (e) => {
        e.preventDefault()
        const {sumPay} = currentSumData
        let tempSum
        let tempHelperText
        let tempSumNDS
        let tempCommissionCashback
        switch (currentCompany) {
            case "Yandex" :{
                tempSum = sumRound((sumPay / 1.12) * 1.1)
                tempSumNDS = sumRound(sumPay - (sumPay / 1.12))
                tempCommissionCashback = sumRound(tempSum - (sumPay / 1.12))
                tempHelperText = `
                *Размер кэшбека указан для первого пополнения. 
                Кэшбек на повторное пополнение на аналогичную сумму составит 5%, 
                на аккаунт будет зачислено ${sumRound(((sumPay / 1.12) * 1.05))} тг.
                `
                break

            }
            // case "TikTok" :{
            //     tempSum = sumRound((sumPay / 1.12) * 1.05)
            //     tempSumNDS = sumRound(sumPay - (sumPay / 1.12))
            //     tempCommissionCashback = sumRound(tempSum - (sumPay / 1.12))
            //     tempHelperText = `
            //     *Размер кэшбека указан для первого пополнения.
            //     На повторное пополнение на аналогичную сумму будет действовать комиcсия в размере 5%,
            //     на аккаунт будет зачислено ${sumRound((sumPay / 1.12) / 1.05)} тг.
            //     `
            //     break
            // }
            default: {
                tempSum = sumRound((sumPay / 1.12) / 1.05)
                tempSumNDS = sumRound(sumPay - (sumPay / 1.12))
                tempCommissionCashback = sumRound((sumPay / 1.12) - tempSum)
                tempHelperText = ""
                break
            }
        }
        setCurrentSumData({
            ...currentSumData,
            sumRefill: Number(tempSum).toLocaleString("RU-ru"),
            helperText: tempHelperText,
            sum_with_NDS: Number(tempSumNDS).toLocaleString("RU-ru"),
            sum_cashback_commission: Number(tempCommissionCashback).toLocaleString("RU-ru")
        })
    }

    const onChange = (e) => {
        if(/^([1-9][0-9]*([.,][0-9]*)?)?$/.test(e.target.value)){
            setCurrentSumData({...currentSumData, sumPay: e.target.value})
        }
        if(e.target.value === ""){
            setCurrentSumData(initialCurrentSumData)
        }
    }


  return(
      <Box id="click_calculate" name="Кнопка Рассчитать" component="form" onSubmit={calculateClickHandle} className={s.calculatorBlock}>
          <div className={s.wrapperBlock}>
              <h2 className={s.titleHero}>Калькулятор пополнения</h2>
              <div className={s.companyContainer}>
                  {
                      companiesImg.map((companyElem, index) => {
                          const {title, img} = companyElem
                          return <div
                              key={index}
                              className={`${s.companyCard} ${currentCompany === title && s.activeCard}`}
                              onClick={ () => handleChangeCompany(title)}
                          >
                              {
                                  img({textColor: "#000"})
                              }
                          </div>
                      })
                  }
              </div>

              <div className={s.calculatorContainer}>

                  <CustomInput
                      className={s.customInput}
                      type="text"
                      label="Сумма к оплате"
                      value={currentSumData.sumPay}
                      onBlur={()=>{}}
                      onChange={onChange}
                  />

                  <CustomInput
                      className={s.customInput}
                      type="text"
                      label="НДС"
                      disabled
                      value={currentSumData.sum_with_NDS}
                      onBlur={()=>{}}
                  />


                  <CustomInput
                      className={s.customInput}
                      type="text"
                      label={currentSumData.cashback_commission ? currentSumData.cashback_commission : "Комиссия / Кэшбек"}
                      disabled
                      value={currentSumData.sum_cashback_commission }
                      onBlur={()=>{}}
                  />

                  <CustomInput
                      className={s.customInput}
                      type="text"
                      label="Сумма пополнения"
                      disabled
                      value={currentSumData.sumRefill}
                      onBlur={()=>{}}
                  />

              </div>

              {
                  currentCompany !== "Yandex"
                      ? <span className={s.calcHelperText}>Зачисление на аккаунт будет в USD, курс конвертации фиксируется в момент поступления денег по банку АО «Народный Банк Казахстана».</span>
                      : null
              }

              {
                  currentSumData.helperText && <span className={s.calcHelperText}>{ currentSumData.helperText }</span>
              }



              <Button
                  type="submit"
                  className={s.calculateBtn}
                  disabled={!currentSumData.sumPay}
              >
                  Расcчитать
              </Button>
          </div>
      </Box>
  )
}

export default CalculatorBlock